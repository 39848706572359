import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

interface Props {
  items: NoteItem[]
}

interface NoteItem {
  id: string
  name: string
  eyecatch: string
  noteUrl: string
}

const CasestudyList: React.FC<Props> = ({ items }) => (
  <List>
    {items.map((item: NoteItem) => {
      if (item !== null) {
        let thumbUrl = ''
        if (item && Object.prototype.hasOwnProperty.call(item, 'eyecatch')) {
          thumbUrl = item.eyecatch
        }
        return (
          <li key={item.id}>
            <a href={item.noteUrl} target="_blank" rel="noopener noreferrer">
              <CaseItem>
                <CaseItemFigure>
                  <CaseItemFigureInner>
                    {item &&
                    Object.prototype.hasOwnProperty.call(item, 'eyecatch') ? (
                      <Image
                        src={thumbUrl}
                        alt=""
                        fill
                        style={{ objectFit: 'cover' }}
                        id={`caseImg_${item.id}`}
                        onLoad={() => {
                          const imageElement: HTMLElement =
                            document.getElementById(`caseImg_${item.id}`)!
                          imageElement.style.opacity = '1'
                        }}
                        sizes="33vw"
                        priority={false}
                      />
                    ) : (
                      <> </>
                    )}
                  </CaseItemFigureInner>
                </CaseItemFigure>
                <CaseItemTitle>{item.name}</CaseItemTitle>
              </CaseItem>
            </a>
          </li>
        )
      }
      return <> </>
    })}
  </List>
)
export default CasestudyList

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px;

  & > li {
    width: calc(33.33% - 32px);
    margin-right: 16px;
    margin-left: 16px;

    &:nth-child(n + 4) {
      margin-top: 48px;
    }

    a {
      transition: opacity 0.6s ease-out;
      color: #000;
      text-decoration: none;

      &:hover {
        transition: opacity 0.1s ease-out;
        opacity: 0.8;
      }
    }
  }

  ${Media.mobileMiddle} {
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;

    & > li {
      width: 100%;
      margin-right: 0;
      margin-left: 0;

      & + li {
        margin-top: 40px;
      }
      &:nth-child(n + 4) {
        margin-top: 40px;
      }
    }
  }
`
const CaseItem = styled.div``
const CaseItemFigure = styled.div``

const CaseItemFigureInner = styled.div`
  position: relative;
  padding-top: 75%;
  overflow: hidden;
  border-radius: 10px 10px;
  background-color: #fafafa;

  & > div {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100%;

    img {
      transition: opacity 1s ${theme.easing.easeOutSine} 0.3s;
      opacity: 0;
    }
  }
`

const CaseItemTitle = styled.div`
  ${theme.typography.DefNotoSansBodyBold}
  display: -webkit-box; /* stylelint-disable-line */
  -webkit-line-clamp: 2; /* stylelint-disable-line */
  -webkit-box-orient: vertical; /* stylelint-disable-line */
  margin-top: 30px;
  overflow: hidden;
`
