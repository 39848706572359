import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'
import Grid from '../../shared/Grid'

export const AdvantagesSection: React.FC = () => (
  <StyledDiv>
    <Container size="large">
      <DualLangHeading en="ADVANTAGES" ja="RYDE PASS導入の様々なメリット" />
      <GridWrap>
        <Grid by={3} $mobile={1} $desktop={3} $tablet={1}>
          <GridItem>
            <Icon>
              <Image
                src="/images/svg/top_advantage_01.svg"
                width={140}
                height={140}
                alt=""
              />
            </Icon>
            <Title>
              利用者が使いやすい
              <br />
              UI・UX
            </Title>
            <Description>
              利用者からは一貫して高水準の評価を頂いており、RYDE
              PASSの使いやすさが交通利用者のメリットとなっております。
            </Description>
          </GridItem>
          <GridItem>
            <Icon>
              <Image
                src="/images/svg/top_advantage_02.svg"
                width={140}
                height={140}
                alt=""
              />
            </Icon>
            <Title>
              デジタル化による
              <br />
              業務効率化
            </Title>
            <Description>
              RYDE
              PASS導入により、オンライン販売や定期券のオンライン申請で窓口業務を削減させることができます。
            </Description>
          </GridItem>
          <GridItem>
            <Icon>
              <Image
                src="/images/svg/top_advantage_03.svg"
                width={140}
                height={140}
                alt=""
              />
            </Icon>
            <Title>
              データ分析を
              <br />
              まちづくりにも活用
            </Title>
            <Description>
              RYDE
              PASSで取得できるデータを利用して、移動のデジタル化のみならず沿線の活性化という地域開発にも活かすことができます。
            </Description>
          </GridItem>
        </Grid>
      </GridWrap>
    </Container>
  </StyledDiv>
)

const StyledDiv = styled.div`
  position: relative;
  margin-top: 132px;

  ${Media.mobileMiddle} {
    margin-top: 80px;
    overflow: hidden;
  }
`

const GridWrap = styled.div`
  margin-top: 42px;

  ${Media.mobileMiddle} {
    margin-top: 40px;
    & > div {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
`

const GridItem = styled.div`
  position: relative;
`

const Icon = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
`

const Title = styled.div`
  position: relative;
  ${theme.typography.FontNotoBold}
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
`

const Description = styled.div`
  position: relative;
  margin-top: 20px;
  line-height: 2;
`
