import styled from 'styled-components'

import Media from '../../utils/Media'

export const ConditionalBR = styled.br<{
  $desktop?: boolean
  $tablet?: boolean
  $mobile?: boolean
}>`
  ${Media.desktop} {
    display: ${(p) => (p.$desktop === false ? `none` : undefined)};
  }
  ${Media.tablet} {
    display: ${(p) => (p.$tablet === false ? `none` : undefined)};
  }
  ${Media.mobile} {
    display: ${(p) => (p.$mobile === false ? `none` : undefined)};
  }
`
