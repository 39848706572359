import React from 'react'
import styled from 'styled-components'

import Media from '../../../utils/Media'
import Card from '../../shared/Card'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'
import Grid from '../../shared/Grid'

export const ProblemSection: React.FC = () => (
  <StyledDiv>
    <Container size="large">
      <DualLangHeading
        en="PROBLEM"
        ja="自治体さま・交通事業者さまが抱える課題"
      />

      <GridWrap>
        <Grid by={3} $gutter={15} $tablet={1} $mobile={1}>
          <div>
            <Card
              $bgColor="#e6eefa"
              indexLabel="CASE.1"
              text="デジタル化を進める上で多様なデータを一元化する必要があるが、様々な調整に時間とリソースを要するため自社での開発が難しい。"
            >
              地域交通のデジタル化が
              <br />
              簡単に実現できない
            </Card>
          </div>
          <div>
            <Card
              $bgColor="#e6eefa"
              indexLabel="CASE.2"
              text="自社交通の見える化が進んでおらず、データを用いた検証やマーケティングができない。ゆえに利用者目線の開発が自社ではしづらい。"
            >
              利用者目線での
              <br />
              サービス開発ができない
            </Card>
          </div>
          <div>
            <Card
              $bgColor="#e6eefa"
              indexLabel="CASE.3"
              text="独自でデジタル化を進めようとしても、商圏の狭さや単価の安さのために採算が合わない。開発ができても保守や運用も考えると難しい。"
            >
              自社で開発するには
              <br />
              採算が合わない
            </Card>
          </div>
        </Grid>
      </GridWrap>
    </Container>
  </StyledDiv>
)

const StyledDiv = styled.div`
  position: relative;
  margin-top: 132px;

  ${Media.mobileMiddle} {
    margin-top: 54px;
    overflow: hidden;
  }
`

const GridWrap = styled.div`
  margin: 40px 0 0 0;

  ${Media.mobileTablet} {
    & > div > div {
      & + div {
        margin-top: 40px;
      }
    }
  }
`
/*
const GridItem = styled.div`
  height: 100%;
  padding: 25px 18px 60px 18px;
  border-radius: 20px;
  background: #e6eefa;
`
const Index = styled.div`
  ${theme.typography.FontOverpassBold}
  margin-bottom: 8px;
  color: #1e9af3;
  font-size: 24px;
  text-align: center;
`

const Title = styled.div`
  ${theme.typography.FontNotoBold}
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
`

const Description = styled.div`
  position: relative;
  margin-top: 23px;
  padding-top: 19px;
  border-top: 2px solid #1e9af3;
  letter-spacing: 0.1em;
  line-height: 2;
`
*/
