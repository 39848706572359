import Image from 'next/image'
import React from 'react'
import styled, { css } from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'

export const SolutionSection: React.FC = () => (
  <StyledDiv>
    <Container size="large">
      <DualLangHeading en="SOLUTION" ja="RYDE PASSで実現できること" />
      <Stack>
        <Grid className="ImgLeft">
          <ItemImg $position="left">
            <ItemImgInner $position="left">
              <Image
                src="/images/picture/top_solution_01.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
              />
            </ItemImgInner>
          </ItemImg>
          <ItemHead $position="right">
            <ItemHeadIndex>
              <span>1</span>
            </ItemHeadIndex>
            <ItemHeadTitle>
              <span>簡単にデジタルチケットが発券可能に。</span>
            </ItemHeadTitle>
          </ItemHead>
          <ItemBody>
            <p>
              フリーパス、観光施設と連携した往復券、定期券など、
              交通分野の様々な乗車券・乗船券等々を、わかりやすく・使いやすいデジタルサービスとして簡単に実現できます。
              <br />
              更に、地域の周遊促進を目的とした「フリーパス+地域特典」など、自社交通のみならず地域に寄与した企画や連携も可能です。
              <br />
              <br />
              一元化されたプラットフォームとして、多くの地域交通 /
              二次交通や自治体などで採用されており、90以上の交通で300以上のデジタルチケットが発券されています。
            </p>
          </ItemBody>
        </Grid>
        <Grid className="ImgRight">
          <ItemImg $position="right">
            <ItemImgInner $position="right">
              <Image
                src="/images/picture/top_solution_02.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
              />
            </ItemImgInner>
          </ItemImg>
          <ItemHead $position="left">
            <ItemHeadIndex>
              <span>2</span>
            </ItemHeadIndex>
            <ItemHeadTitle>
              <span>見えてこなかった自社交通の今が見えるように。</span>
            </ItemHeadTitle>
          </ItemHead>
          <ItemBody>
            <p>
              デジタルチケットの発券や移動に関するデータの見える化は勿論のこと、データを用いた新たな仮説検証やマーケティング施策を、誰でも簡単に実践できます。
              <br />
              <br />
              RYDE
              PASSを経由した売上や利用実績の管理、利用されたお客様の属性等の簡易的な分析まで、全て管理画面上で実現可能となっています。
            </p>
          </ItemBody>
        </Grid>

        <Grid className="ImgLeft">
          <ItemImg $position="left">
            <ItemImgInner $position="left">
              <Image
                src="/images/picture/top_solution_03.png"
                fill
                style={{ objectFit: 'contain' }}
                alt=""
              />
            </ItemImgInner>
          </ItemImg>
          <ItemHead $position="right">
            <ItemHeadIndex>
              <span>3</span>
            </ItemHeadIndex>
            <ItemHeadTitle>
              <span>重い費用負担なしで自社交通のデジタル化が可能に。</span>
            </ItemHeadTitle>
          </ItemHead>
          <ItemBody>
            <p>
              RYDE
              PASSを使えば自社交通のデジタル化に伴う開発費用はなし。更に保守費用もかからず、重い費用負担なしで自社交通のデジタル化が簡単に可能になります。
              <br />
              <br />
              どの事業者さま・自治体さまも、システムの「開発」や「保守」を気にすることなく持続的にご利用ができます。
            </p>
          </ItemBody>
        </Grid>
      </Stack>
    </Container>
  </StyledDiv>
)

const StyledDiv = styled.div`
  position: relative;
  margin-top: 132px;

  ${Media.mobileMiddle} {
    margin-top: 80px;
    overflow: hidden;
  }
`

const Stack = styled.div`
  display: grid;
  position: relative;
  gap: 56px;
  margin: 40px 0 0 0;

  ${Media.mobileMiddle} {
    margin: 40px 0 0 0;
  }
`
const ItemHead = styled.div<{ $position: 'left' | 'right' }>`
  display: flex;
  position: relative;
  grid-area: itemHead;
  flex-shrink: 0;
  height: 104px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: calc(var(--vw) * 50);
    height: 100%;
    background-color: #1e9af3;
  }

  /* stylelint-disable */
  ${(props) =>
    props.$position === 'right' &&
    css`
      padding-left: 11%;
      &::before {
        left: 0;
        border-radius: 9999px 0 0 9999px;
      }
    `}
  ${(props) =>
    props.$position === 'left' &&
    css`
      padding-right: 11%;
      &::before {
        right: 0;
        border-radius: 0 9999px 9999px 0;
      }
    `}
  /* stylelint-enable */

  ${Media.mobileMiddle} {
    padding-left: 0;
    padding-right: 0;
    &::before {
      width: calc(100% + 20px);
    }
  }
`

const ItemHeadIndex = styled.div`
  display: flex;
  position: relative;
  grid-area: itemHead;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 88px;

  span {
    ${theme.typography.FontOverpassBold}
    padding-top: 0.2em;
    color: #fff;
    font-size: 80px;
    line-height: 1;
  }
  ${Media.mobileMiddle} {
    width: 67px;
    span {
      font-size: 48px;
    }
  }
`

const ItemHeadTitle = styled.div`
  display: flex;
  position: relative;
  grid-area: itemHead;
  flex-shrink: 1;
  align-items: center;

  span {
    ${theme.typography.FontNotoBold}
    color: #fff;
    font-size: 20px;
    line-height: 1.8;
  }
  ${Media.mobileMiddle} {
    span {
      font-size: 18px;
    }
  }
`

const ItemImg = styled.div<{ $position: 'left' | 'right' }>`
  display: flex;
  position: relative;
  grid-area: itemImg;
  flex-shrink: 0;

  justify-content: ${(props) =>
    props.$position === 'left' ? 'flex-end' : 'flex-start'};
  height: 500px;

  ${Media.mobileMiddle} {
    height: 242px;
  }
`
const ItemImgInner = styled.div<{ $position: 'left' | 'right' }>`
  position: absolute;
  top: 0;
  right: ${(props) => (props.$position === 'left' ? '0' : 'auto')};
  left: ${(props) => (props.$position === 'left' ? 'auto' : '0')};
  flex-shrink: 0;
  width: calc(var(--vw) * 50);
  height: 100%;
  overflow: hidden;

  border-radius: ${(props) =>
    props.$position === 'left' ? '0 16px 16px 0' : '16px 0 0 16px'};
  background-color: #e6eefa;

  img {
    width: 100%;
    height: auto;
  }

  ${Media.mobileMiddle} {
    width: calc(100% + 20px);
  }
`

const ItemBody = styled.div`
  grid-area: itemBody;
  flex-shrink: 0;

  p {
    letter-spacing: 0.1em;
    line-height: 2;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-template-rows: auto 1fr;
  gap: 30px;

  & > div {
    flex-shrink: 0;
    width: 100%;
  }

  &.ImgLeft {
    grid-template-areas:
      'itemImg itemHead'
      'itemImg itemBody';
    ${ItemBody} {
      padding-left: 11%;
    }
  }
  &.ImgRight {
    grid-template-areas:
      'itemHead itemImg'
      'itemBody itemImg';
    ${ItemBody} {
      padding-right: 11%;
    }
  }

  ${Media.mobileMiddle} {
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    gap: 18px;

    &.ImgLeft {
      grid-template-areas:
        'itemHead'
        'itemImg'
        'itemBody';
      ${ItemBody} {
        padding-left: 0;
      }
    }
    &.ImgRight {
      grid-template-areas:
        'itemHead'
        'itemImg'
        'itemBody';
      ${ItemBody} {
        padding-right: 0;
      }
    }
  }
`
