import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import Media from '../../utils/Media'

interface PartnerItem {
  image: string
  alt: string
}

/* stylelint-disable */

const list = [
  { image: '/images/logo/partner/kumagawa.png', alt: 'くま川鉄道株式会社' },
  { image: '/images/logo/partner/hiroshimabus.png', alt: '広島バス株式会社' },
  { image: '/images/logo/partner/ooigawa.png', alt: '大井川鐵道' },
  { image: '/images/logo/partner/echizen.png', alt: 'えちぜん鉄道' },
  {
    image: '/images/logo/partner/tangokairiku.png',
    alt: '丹後海陸交通株式会社',
  },
  { image: '/images/logo/partner/izuhakone.png', alt: '伊豆箱根鉄道' },
  { image: '/images/logo/partner/chibachuo.png', alt: '千葉中央バス株式会社' },
  { image: '/images/logo/partner/minamichita.png', alt: '南知多町' },
  {
    image: '/images/logo/partner/aichikanjyo.png',
    alt: '愛知環状鉄道株式会社',
  },
  { image: '/images/logo/partner/fukuitetsudo.png', alt: '福井鉄道株式会社' },
  { image: '/images/logo/partner/toyotetsubus.png', alt: '豊鉄バス株式会社' },
  {
    image: '/images/logo/partner/toyohashitetsudo.png',
    alt: '豊橋鉄道株式会社',
  },
  { image: '/images/logo/partner/akechitetsudo.png', alt: '明知鉄道株式会社' },
  { image: '/images/logo/partner/ohmitetsudo.png', alt: '近江鉄道株式会社' },
  {
    image: '/images/logo/partner/shigarakitetsudo.png',
    alt: '信楽高原鐵道株式会社',
  },
  { image: '/images/logo/partner/meitetsukaijyo.png', alt: '名鉄海上観光船' },
  { image: '/images/logo/partner/kitaena.png', alt: '北恵那交通株式会社' },
  {
    image: '/images/logo/partner/chibagreenbus.png',
    alt: 'ちばグリーンバス株式会社',
  },
  { image: '/images/logo/partner/keiseigroup.png', alt: '京成グループ' },
  { image: '/images/logo/partner/yonakoshi.png', alt: '米子市' },
  { image: '/images/logo/partner/tarumitetsudo.png', alt: '樽見鉄道株式会社' },
  {
    image: '/images/logo/partner/keiseitransit.png',
    alt: '京成トランジットバス',
  },
  { image: '/images/logo/partner/naritaairport.png', alt: '成田空港交通' },
  { image: '/images/logo/partner/kokashi.png', alt: '甲賀市' },
  { image: '/images/logo/partner/shinkibus.png', alt: '神姫バス株式会社' },
  {
    image: '/images/logo/partner/yamagatatetsudo.png',
    alt: '山形鉄道株式会社',
  },
  {
    image: '/images/logo/partner/hinomarujidousya.png',
    alt: '日ノ丸自動車株式会社',
  },
  { image: '/images/logo/partner/nihonkoutuu.png', alt: '日本交通株式会社' },
  {
    image: '/images/logo/partner/yasugishi.png',
    alt: '安来市',
  },
  {
    image: '/images/logo/partner/alpico.png',
    alt: 'アルピコグループ',
  },

  {
    image: '/images/logo/partner/hokusotetsudo.png',
    alt: '北総鉄道株式会社',
  },
  {
    image: '/images/logo/partner/iwategingatetsudo.png',
    alt: 'ＩＧＲいわて銀河鉄道株式会社',
  },
  {
    image: '/images/logo/partner/miyagikoutsuu.png',
    alt: '宮城交通株式会社',
  },
  {
    image: '/images/logo/partner/iwakunibus.png',
    alt: 'いわくにバス株式会社',
  },
  {
    image: '/images/logo/partner/aoimoritetsudo.png',
    alt: '青い森鉄道株式会社',
  },

  { image: '/images/logo/partner/isetetsudo.png', alt: '伊勢鉄道株式会社' },
  { image: '/images/logo/partner/igatetsudo.png', alt: '伊賀鉄道株式会社' },
  { image: '/images/logo/partner/hokutetsunoto.png', alt: '北鉄能登バス' },
  { image: '/images/logo/partner/tagawashi.png', alt: '田川市' },
  { image: '/images/logo/partner/okayamadenkikido.png', alt: '岡山電気軌道' },
  {
    image: '/images/logo/partner/hisatsuorange.png',
    alt: '肥薩おれんじ鉄道株式会社',
  },
  {
    image: '/images/logo/partner/hankaidensya.png',
    alt: '阪堺電車',
  },
]

/* stylelint-enable */

export const Partners = () => (
  <>
    <List>
      {list.map((item: PartnerItem) => (
        <li key={item.image}>
          <Item>
            <Image
              src={item.image}
              fill
              style={{ objectFit: 'contain' }}
              alt={item.alt}
              sizes="16vw"
            />
          </Item>
        </li>
      ))}
    </List>
    {/**
     * <Annotation>※順不同</Annotation>
     */}
  </>
)

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;

  li {
    width: 170px;
    height: 80px;
    padding: 10px 14px;
  }

  ${Media.mobileTablet} {
    li {
      width: 50%;
      padding-top: 47.05% & > div {
        width: 100%;
        height: 100%;
      }
    }
  }
`

const Item = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
