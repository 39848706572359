import React from 'react'
import styled from 'styled-components'

import Media from '../../../utils/Media'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'
import { Partners } from '../../shared/Partners'

export const ClientsSection: React.FC = () => (
  <StyledDiv>
    <Container size="large">
      <DualLangHeading en="CLIENTS" ja="導入顧客" />
      <Grid>
        <Partners />
      </Grid>
    </Container>
  </StyledDiv>
)

const StyledDiv = styled.div`
  position: relative;
  margin-top: 132px;

  ${Media.mobileMiddle} {
    margin-top: 80px;
    overflow: hidden;
  }
`

const Grid = styled.div`
  margin: 40px -32px 0 -32px;

  ${Media.mobileMiddle} {
    margin: 40px 0 0 0;
  }
`
