import React, { ReactNode } from 'react'
// import styled, { css } from 'styled-components'
import styled from 'styled-components'

import theme from '../../theme'
import Media from '../../utils/Media'

interface Props {
  text: string
  children: ReactNode
  $bgColor?: string
  indexLabel?: string | undefined
}
const Card: React.FC<Props> = ({
  text,
  children,
  $bgColor = '#fff',
  indexLabel = undefined,
}) => (
  <Wrap $bgColor={$bgColor}>
    {indexLabel && <Index>{indexLabel}</Index>}
    <Title>{children}</Title>
    <Description>{text}</Description>
  </Wrap>
)

export default Card

const Wrap = styled.div<{ $bgColor: string }>`
  height: 100%;
  padding: 25px 18px 60px 18px;
  border-radius: 20px;
  background-color: ${(props) => props.$bgColor};

  ${Media.mobileMiddle} {
    padding: 25px 18px 38px 18px;
  }
`
const Index = styled.div`
  ${theme.typography.FontOverpassBold}
  margin-bottom: 8px;
  color: #1e9af3;
  font-size: 24px;
  text-align: center;
`

const Title = styled.div`
  ${theme.typography.FontNotoBold}
  font-size: 20px;
  line-height: 1.8;
  text-align: center;
`

const Description = styled.div`
  position: relative;
  margin-top: 23px;
  padding-top: 19px;
  border-top: 2px solid #1e9af3;
  letter-spacing: 0.1em;
  line-height: 2;
`
