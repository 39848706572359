import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import ButtonRounded from '../../shared/ButtonRounded'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'
import { useMatchMedia } from '../../utils/useMatchMedia'

export const ServiceSection: React.FC = () => {
  const isMobileSize = useMatchMedia('(max-width: 800px)')

  return (
    <StyledDiv>
      <Container size="large">
        <DualLangHeading en="SERVICE" ja="RYDE PASSのサービス" />
        <Grid>
          <div>
            <Head>
              <HeadIndex>
                <span>1</span>
              </HeadIndex>
              <HeadTitle>
                <span>RYDE PASSの活用</span>
              </HeadTitle>
            </Head>
            <Middle>
              <Desctiption>
                管理画面から登録するだけでOK！自社交通のデジタル化が今すぐに実現できます。
              </Desctiption>
              <Thumbnail>
                <Image
                  src="/images/picture/top_service_01.png"
                  fill
                  style={{ objectFit: 'contain', objectPosition: 'center' }}
                  alt=""
                />
              </Thumbnail>
            </Middle>

            <ButtonRounded
              href="/usage"
              color="blue"
              height={isMobileSize ? '46' : '72'}
              icon="arrow"
            >
              詳しく見る
            </ButtonRounded>
          </div>
          <div>
            <Head>
              <HeadIndex>
                <span>2</span>
              </HeadIndex>
              <HeadTitle>
                <span>
                  自社アプリに
                  <br />
                  RYDE PASSの基盤を活用
                </span>
              </HeadTitle>
            </Head>
            <Middle>
              <Desctiption>
                管理画面から登録するだけでOK！自社交通のデジタル化が今すぐに実現できます。
              </Desctiption>
              <Thumbnail>
                <Image
                  src="/images/picture/top_service_02.png"
                  fill
                  style={{ objectFit: 'contain', objectPosition: 'center' }}
                  alt=""
                />
              </Thumbnail>
            </Middle>
            <ButtonRounded
              href="/foundation"
              color="blue"
              height={isMobileSize ? '46' : '72'}
              icon="arrow"
            >
              詳しく見る
            </ButtonRounded>
          </div>
        </Grid>
      </Container>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
  margin-top: 132px;

  ${Media.mobileMiddle} {
    margin-top: 43px;
    padding-top: 36px;
    padding-bottom: 40px;
    overflow: hidden;
    background-color: #e6eefa;
  }
`

const Grid = styled.div`
  display: flex;
  margin-top: 42px;

  & > div {
    position: relative;
    width: 50%;
    padding-top: 32px;
    padding-bottom: 60px;

    &:hover {
      &::before {
        transition: opacity 0.2s ease-out;
        opacity: 0.4;
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      width: calc(var(--vw) * 100);
      height: 100%;
      transition: opacity 0.6s ease-out;
      opacity: 0;
      background-color: #1e9af3;
    }

    &:first-child {
      padding-right: 10%;
      &::before {
        right: 0;
        border-radius: 0 16px 16px 0;
      }
    }
    &:last-child {
      padding-left: 10%;
      &::before {
        left: 0;
        border-radius: 16px 0 0 16px;
      }
    }
  }

  ${Media.mobileMiddle} {
    flex-direction: column;
    gap: 82px;
    & > div {
      width: 100%;
      padding: 0 !important;
      &::before {
        display: none;
      }
    }
  }
`

const Head = styled.div`
  display: flex;
  position: relative;
`
const HeadIndex = styled.div`
  display: flex;
  ${theme.typography.FontOverpassBold}
  flex-shrink: 0;
  width: 90px;
  color: #1e9af3;

  span {
    transform: translateY(0.1em);
    font-size: 80px;
    line-height: 1;
  }

  ${Media.mobileMiddle} {
    justify-content: center;
    width: 56px;
    span {
      font-size: 48px;
    }
  }
`

const HeadTitle = styled.div`
  display: flex;
  align-items: center;
  ${theme.typography.FontNotoBold}
  font-size: 24px;
  line-height: 1.75;

  ${Media.mobileMiddle} {
    font-size: 18px;
  }
`
const Middle = styled.div`
  ${Media.mobileMiddle} {
    display: flex;
    flex-direction: column-reverse;
  }
`

const Desctiption = styled.div`
  position: relative;
  margin-top: 29px;
  line-height: 2;

  ${Media.mobileMiddle} {
    margin-top: 0;
    margin-bottom: 20px;
  }
`

const Thumbnail = styled.div`
  position: relative;
  margin: 50px 0 26px 0;
  aspect-ratio: 408 / 238;

  ${Media.mobileMiddle} {
    margin: 10px 0 10px 0;
    aspect-ratio: 320 / 246;
  }
`
