import React from 'react'

import { Maybe, NewsCollectionFieldsFragment } from '../../../generated/graphql'
import Layout from '../../shared/Layout'
import { CasestudySection } from '../NewIndexTemplate/CasestudySection'
import { NewsSection } from '../NewIndexTemplate/NewsSection'
import { AdvantagesSection } from './AdvantagesSection'
import { ClientsSection } from './ClientsSection'
import { HeroSection } from './HeroSection'
import { ProblemSection } from './ProblemSection'
import { ServiceSection } from './ServiceSection'
import { SolutionSection } from './SolutionSection'

interface StaticNewIndexProps {
  newsItems: Maybe<NewsCollectionFieldsFragment>[]
  notePosts: any
}

const BizTopTemplate: React.FC<StaticNewIndexProps> = ({
  newsItems,
  notePosts,
}) => {
  let noteContents: any[] = notePosts.contents
  if (noteContents.length > 3) {
    noteContents = noteContents.slice(0, 3)
  }

  return (
    <Layout>
      <HeroSection />
      <ProblemSection />
      <SolutionSection />
      <ServiceSection />
      <AdvantagesSection />
      <CasestudySection casestudyItems={noteContents} />
      <ClientsSection />
      <NewsSection newsItems={newsItems} />
    </Layout>
  )
}

export default BizTopTemplate
