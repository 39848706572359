import React from 'react'
import styled from 'styled-components'

import Media from '../../../utils/Media'
import ButtonRounded from '../../shared/ButtonRounded'
import CasestudyList from '../../shared/CasestudyList'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'

type Props = {
  casestudyItems: any[]
}

export const CasestudySection: React.FC<Props> = ({ casestudyItems }) => (
  <StyledDiv>
    <Container size="large">
      <DualLangHeading en="CASE STUDY" ja="導入したお客様の声" />
      <Grid>
        <CasestudyList items={casestudyItems} />
      </Grid>

      <LinkButton>
        <ButtonRounded
          href="https://ryde-inc.jp/casestudy?page=1"
          color="blue"
          height="72"
          icon="arrow"
        >
          他の導入事例も見る
        </ButtonRounded>
      </LinkButton>
    </Container>
  </StyledDiv>
)

const LinkButton = styled.div`
  width: 496px;
  margin: 110px auto 0 auto;

  a {
    font-size: 20px;
  }

  ${Media.mobileTablet} {
    width: 100%;
    margin: 35px auto 0 auto;

    a {
      font-size: 16px;
    }
  }
`

const StyledDiv = styled.div`
  position: relative;
  margin-top: 135px;

  ${Media.mobileMiddle} {
    margin-top: 103px;
  }
`

const Grid = styled.div`
  margin-top: 40px;
`
