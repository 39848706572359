import Image from 'next/image'
import React, { useState } from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import ButtonRounded from '../../shared/ButtonRounded'
import { ConditionalBR } from '../../shared/ConditionalBR'
import { Container } from '../../shared/Container'
import ImagesSlider from '../../shared/ImagesSlider'
import { useMatchMedia } from '../../utils/useMatchMedia'

export const HeroSection: React.FC = () => {
  const isMobileSize = useMatchMedia('(max-width: 800px)')
  const [loadComplete, setLoadComplete] = useState(false)
  const onLoadCompleteImage = () => {
    setLoadComplete(true)
  }
  return (
    <>
      <StyledDiv $onload={loadComplete}>
        <Container size="large">
          <Grid>
            <ItemTitle>
              <BgLine>
                <Image
                  src="/images/svg/line-white.svg"
                  width="2062"
                  height="1651"
                  alt=""
                  onLoad={() => {
                    onLoadCompleteImage()
                  }}
                />
              </BgLine>
              <ItemTitleH1>
                地域交通のデジタル化を
                <ConditionalBR $desktop={false} $tablet={true} $mobile={true} />
                RYDE PASSで簡単に。
              </ItemTitleH1>
            </ItemTitle>
            <ItemImg>
              <Image
                src="/images/picture/top_screenshot.png"
                width="701"
                height="408"
                alt=""
              />
            </ItemImg>
            <ItemBody>
              <Lead>
                導入交通事業会社 <span>90</span> 社以上
              </Lead>
              <Btns>
                <ButtonRounded
                  href="https://form.run/@ryde-pass-download"
                  color="blue"
                  icon="arrow"
                  height={isMobileSize ? '50' : '72'}
                >
                  資料請求をする
                </ButtonRounded>
                <ButtonRounded
                  href="https://form.run/@ryde-pass-contact"
                  color="blue"
                  icon="arrow"
                  height={isMobileSize ? '50' : '72'}
                >
                  導入する
                </ButtonRounded>
              </Btns>
            </ItemBody>
          </Grid>
        </Container>
      </StyledDiv>
      <ImagesSlider />
    </>
  )
}

const ItemTitle = styled.div`
  ${theme.typography.FontNotoBold}
  position: relative;
  grid-area: itemTitle;
`

const ItemTitleH1 = styled.div`
  position: relative;
  transition: opacity 0.4s ease-out 0.15s;
  opacity: 0;
  font-size: 36px;
  line-height: 2;

  ${Media.mobileMiddle} {
    font-size: 24px;
    font-size: calc(100 / 360 * 24vw);
  }
`

const ItemImg = styled.div`
  display: flex;
  position: relative;
  grid-area: itemImg;
  flex-shrink: 0;
  justify-content: flex-end;
  padding-right: 17%;
  transition: opacity 0.7s ease-in-out;
  opacity: 0;

  ${Media.mobileMiddle} {
    img {
      max-width: 120%;
      height: auto;
    }
  }
`

const ItemBody = styled.div`
  grid-area: itemBody;
  flex-shrink: 0;

  p {
    letter-spacing: 0.1em;
    line-height: 2;
  }
`

const Lead = styled.div`
  ${theme.typography.FontNotoBold}
  position: relative;
  transition: opacity 0.4s ease-out 0.3s;
  opacity: 0;
  color: #000;
  font-size: 20px;
  letter-spacing: 0.05em;
  line-height: 2;

  span {
    display: inline-block;
    margin-right: 22px;
    transform: translateY(0.03em);
    ${theme.typography.FontOverpassMediumItalic}
    font-size: 96px;
    line-height: 1;
    vertical-align: baseline;
  }

  ${Media.mobileMiddle} {
    margin-top: 11px;
    margin-right: 4px;
    font-size: 16px;
    span {
      font-size: 64px;
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    'itemImg itemTitle'
    'itemImg itemBody';
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-template-rows: auto 1fr;
  gap: 30px;

  & > div {
    flex-shrink: 0;
    width: 100%;
  }

  ${Media.mobileMiddle} {
    grid-template-areas:
      'itemTitle'
      'itemImg'
      'itemBody';
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
    gap: 18px;
  }
`

const Btns = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 48px;
  transition: opacity 0.4s ease-out 0.45s;
  opacity: 0;

  & > * {
    width: calc(50% - 16px);
  }

  ${Media.mobileMiddle} {
    gap: 10px;
    margin-top: 18px;
    a {
      padding-right: 16px;
      font-size: 12px;
    }
  }
`

const BgLine = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  transition: opacity 0.4s ease-out 0.6s;
  opacity: 0;

  img {
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  ${Media.mobileMiddle} {
    top: 128%;
    left: 58%;
    img {
      transform: translate(-50%, -50%) scale(0.45);
    }
  }
`

const StyledDiv = styled.div<{ $onload: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  height: 730px;
  overflow: hidden;
  background-color: #e6eefa;

  ${Media.mobileMiddle} {
    overflow: hidden;
  }
  ${ItemImg} {
    opacity: ${(props) => (props.$onload ? '1' : '0')};
  }
  ${ItemTitleH1} {
    opacity: ${(props) => (props.$onload ? '1' : '0')};
  }
  ${Lead} {
    opacity: ${(props) => (props.$onload ? '1' : '0')};
  }
  ${Btns} {
    opacity: ${(props) => (props.$onload ? '1' : '0')};
  }
  ${BgLine} {
    opacity: ${(props) => (props.$onload ? '1' : '0')};
  }

  /* stylelint-disable */
  ${Media.mobileMiddle} {
    /* stylelint-enable */
    height: auto;
    padding-top: calc(100 / 360 * 110vw);
    padding-bottom: 30px;
  }
`
